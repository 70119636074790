import { Cushion, FlexContainer, Space, Typography } from '@pitchero/react-ui';
import PitcheroLogo from 'components/pitchero-logo';
import RankRibbon from 'components/rank-ribbon';
import { Trans } from 'lib/i18n';
import PropTypes from 'prop-types';
import React from 'react';

const ClubRanking = ({ rank, rankGroup }) => {
  return (
    <FlexContainer alignItems="center" component="div">
      {rank && (
        <>
          <a href="https://www.pitchero.com/community" title="Pitchero Rankings">
            <FlexContainer alignItems="center">
              <Cushion right="xsmall">
                <RankRibbon
                  rankGroup={rankGroup}
                  defaultColors={{ colorOne: '#fff', colorTwo: '#fff' }}
                  size={24}
                />
              </Cushion>
              <Typography preset="tag--large" weight="500" color="#fff">
                <Trans i18nKey="rank" values={{ rank }}>
                  {`Rank #${rank}`}
                </Trans>
              </Typography>
            </FlexContainer>
          </a>
          <div
            style={{
              height: '38px',
              width: '1px',
              borderRight: '1px solid white',
              margin: '0 5px',
            }}
          />
        </>
      )}
      <FlexContainer flexDirection="column">
        <Space bottom="xsmall">
          <Typography preset="tag--small" component="div" color="#ababab">
            <Trans i18nKey="common:powered_by">Powered by</Trans>
          </Typography>
        </Space>
        <a href="https://www.pitchero.com" title="Pitchero" aria-label="Pitchero Link">
          <PitcheroLogo width={80} color="#fff" />
        </a>
      </FlexContainer>
    </FlexContainer>
  );
};

ClubRanking.defaultProps = {
  rank: null,
  rankGroup: null,
};

ClubRanking.propTypes = {
  rank: PropTypes.number,
  rankGroup: PropTypes.string,
};

export default React.memo(ClubRanking);
