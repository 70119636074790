import React from 'react';
import PropTypes from 'prop-types';
import IconWithTheme from 'components/icon-with-theme';
import RibbonIcon from 'icons/Ribbon';

const RankGroups = {
  bronze: {
    colorOne: '#FFA37C',
    colorTwo: '#C9440B',
  },
  silver: {
    colorOne: '#FFF',
    colorTwo: '#B4B4B4',
  },
  gold: {
    colorOne: '#FFE291',
    colorTwo: '#FFBD00',
  },
};

const RankRibbon = ({ rankGroup, defaultColors, size }) => {
  const colors = RankGroups[rankGroup] || defaultColors;
  return (
    <IconWithTheme>
      <RibbonIcon name="RIBBON" size={size} {...colors} />
    </IconWithTheme>
  );
};

RankRibbon.propTypes = {
  rankGroup: PropTypes.string,
  defaultColors: PropTypes.shape(),
  size: PropTypes.number,
};
RankRibbon.defaultProps = {
  defaultColors: { colorOne: 'white', colorTwo: 'white' },
  rankGroup: 'none',
  size: 16,
};
export default RankRibbon;
